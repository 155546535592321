body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}








.deskPicture {
  background-position: center;
}

.h-10 {
  height: 10%;
}

@media only screen and (min-width: 768px) {
  .hover-appear {
    opacity:0;
    transition: .5s;
  }
  
  .hover-appear:hover {
    opacity:1;
    background-color: rgba(0,0,0,0.5);
  }

  .img-response {
    visibility: visible;
  }
}


/* .img-response {
  visibility: hidden;
} */

.darken-img {
  object-fit: cover;
  background-color: black;
  opacity: 50%;
}




/* Flip Cards */

@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700;800;900&display=swap');

/* Reseting */




.container{
    transform-style: preserve-3d;
}

.container .box{
    position: relative;
    width: 800px;
    height: 400px;
    margin: 20px;
    transform-style: preserve-3d;
    perspective: 1000px;
    cursor: pointer;
    
}

.container .box .body{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    transform-style: preserve-3d;
    transition: 0.9s ease;
}



.container .box .body .imgContainer{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    transform-style: preserve-3d;
}

.container .box .body .imgContainer img{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.container .box .body .content{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: #333;
    backface-visibility: hidden;
    transform-style: preserve-3d;
    transform: rotateX(180deg);
}

.container .box:hover .body{
    transform: rotateX(180deg);
}

.container .box .body .content div{
    transform-style: preserve-3d;
    padding: 20px;
    /* background: #2E83B7; */
    background: linear-gradient(180deg, #2E83B7,#8ec5e2);
    transform: translateZ(100px);
}

.container .box .body .content div h3{
    letter-spacing: 1px;
}

.icon-service {
  margin: 0rem 4rem 2rem 0rem;
}

.bg-dark-primary {
  background-color: #45677d;
}

.has-bg-img {
  background-image: url("../../images/restaurant.jpg");
  height: 100vh;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

/* @media only screen and (min-width: 768px) {} */

.font-about {
  font-size: calc(10px + .500625vw);
  /* font-size: 1.5rem; */

}

