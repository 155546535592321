.bookkeepingvideoiframe {
    position: absolute;
    /* top: 200; left: 0; */
    width: 90%;
    height: 100%;
  }


  
.bookkeepingvideo {
    position: relative;
    padding-bottom: 56.25%;
     height: 0;
  }
  
.dextvideoiframe {
    position: absolute;
    /* top: 200; left: 200; */
    width: 90%;
    height: 100%;

}