@import './csstemplates/main.css';
@import './index.css';
@import './scss/custom.css';
@import './services.css';
@import './Contact.css';
@import './videos.css';
/* @import './csstemplates/custom.scss'; */


:root {
  --primary: #2E83B7;
  --secondary: #E77338;
  --success: #289A82;
  --danger: #D25425;
  --info: #50A6D3;
}




.services-card {
  min-height: 600px;
};



/* Used for the nav bar buttons */
.hover-button:hover {
  color: var(--primary) !important;

}

.contact-button:hover{
  color: var(--light) !important;
  
}


.bookkeepingvideo {
  position: relative;
  padding-bottom: 56.25%;
   height: 0;
}

.bookkeepingvideoiframe {
  position: absolute;
  /* top: 200; left: 0; */
  width: 90%;
  height: 100%;
}