
.w-50-100 {
    width: 50%
}

@media (max-width: 1200px) {
    .w-50-100 {
        width: 100%;
    }
}
