/* Services */
body{
    margin-top:20px;
    background:#F0F8FF;
    }
  .text-custom{
    color: #0062ff !important;
    }
  
  .blankspace {
    min-height: 200px;
  }
  
  .max-height-100 {
    max-height: 100px;
  }
  
  
  
  /* SErvies Pricing */
  
  body{margin-top:20px;}
  .pricing-box {
    -webkit-box-shadow: 0px 5px 30px -10px rgba(0, 0, 0, 0.1);
            box-shadow: 0px 5px 30px -10px rgba(0, 0, 0, 0.1);
    padding: 35px 50px;
    border-radius: 20px;
    position: relative;
  }
  
  .pricing-box .plan {
    font-size: 34px;
  }
  
  .pricing-badge {
    position: absolute;
    top: 0;
    z-index: 999;
    right: 0;
    width: 100%;
    display: block;
    font-size: 15px;
    padding: 0;
    overflow: hidden;
    height: 100px;
  }
  
  .pricing-badge .badge {
    float: right;
    -webkit-transform: rotate(45deg);
            transform: rotate(45deg);
    right: -67px;
    top: 17px;
    position: relative;
    text-align: center;
    width: 200px;
    font-size: 13px;
    margin: 0;
    padding: 7px 10px;
    font-weight: 500;
    color: #ffffff;
    background: #fb7179;
  }
  .mb-2, .my-2 {
      margin-bottom: .5rem!important;
  }
  p {
      line-height: 1.7;
  }
  
  /* Services Card */

  /* .services-card-bg {
    background-image: linear-gradient(to right top, #50a6d3, #85badf, #afcfea, #d5e4f5, #f8fbff);
    -webkit-box-shadow:0px 0px 48px 12px rgba(133,185,223,0.67);
-moz-box-shadow: 0px 0px 48px 12px rgba(133,185,223,0.67);
box-shadow: 0px 0px 48px 12px rgba(133,185,223,0.67);
  } */
  